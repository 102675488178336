import React, { Component } from 'react'
import { assocPath, curry, o } from 'ramda'

const Context = React.createContext()

export class ProductContext extends Component {
  static Consumer = Context.Consumer

  state = {
    select: curry((productid, key, value) =>
      this.setState(
        o(
          assocPath([productid, 'time'], Date.now()),
          assocPath([productid, key], value)
        )
      )
    ),
  }

  render() {
    const { children } = this.props
    return React.createElement(
      Context.Provider,
      { value: this.state },
      children
    )
  }
}

export default Context
