import React, { Component } from 'react'
import config from './config.json'

const Context = React.createContext()

export class FirebaseProvider extends Component {
  static Consumer = Context.Consumer

  unregisterAuthObserver = () => {}
  initInProgress = false
  state = {}

  componentWillUnmount() {
    this.unregisterAuthObserver()
  }

  initFirebase = async () => {
    if (this.initInProgress || this.state.firebase) return
    this.initInProgress = true
    try {
      const [{ default: fb }] = await Promise.all([
        import('firebase/app'),
        import('firebase/auth'),
        import('firebase/database'),
      ])
      const firebase = fb.initializeApp(config)
      this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
        this.setState({ user })
      })
      this.setState({ firebase })
    } catch (e) {
      console.log(e)
    } finally {
      this.initInProgress = false
    }
  }

  render() {
    const { children } = this.props
    const { user, firebase } = this.state

    return (
      <Context.Provider value={{ user, firebase, init: this.initFirebase }}>
        {children}
      </Context.Provider>
    )
  }
}

export default Context
