/* eslint-disable */
/**
 * React Coverflow
 *
 * Author: andyyou & asalem1
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import styled from 'styled-components'
import { mediaQuery } from 'utils/style'

const GalleryItem = styled.div`
  ${({ hide }) => (hide ? 'visibility: hidden' : 'position: absolute')};
`

const GalleryTitle = styled.div`
  font-size: small;
  text-overflow: clip;
  overflow: hidden;
  height: fit-content;
  padding: 0 0.3rem 0 0.3rem;
  text-align: center;
`

const GalleryImage = styled.div`
  position: relative;
`

const Left = styled(ChevronLeft)`
  cursor: pointer;
  z-index: 101;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 30px;
`

const Right = styled(ChevronRight)`
  cursor: pointer;
  z-index: 101;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 30px;
`

const Gallery = styled.div`
  margin-top: 20px;
  /* height: fit-content; */
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  > ${Right}, ${Left} {
    height: ${({ height }) => `${height}rem`};
  }
  > ${GalleryItem} {
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 33%;
    width: 33%;
    /* height: fit-content; */
    cursor: pointer;
    > ${GalleryImage} {
      padding: 0 0.6rem 0 0.6rem;
      height: ${({ height }) => `${height}rem`} !important;
      width: ${({ height }) => `${height}rem`} !important;
    }
    opacity: 0.5;
    :nth-child(${({ current }) => 2 * (current + 2)}) {
      opacity: 1;
    }
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  padding: 0;
  ${({ hideOverflow }) => (hideOverflow ? `overflow: hidden;` : ``)}
  flex-direction: column;
  align-items: center;
  ${mediaQuery()}
`

const Coverflow = styled.div`
  ${({ squared }) =>
    squared ? `height: 0; padding-bottom: ${squared * 100}%;` : `flex: 1;`}
  position: relative;
  width: 100%;
  margin: 0;
  ${({ hideOverflow }) => (hideOverflow ? `overflow: hidden;` : ``)}
`

const Stage = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  transform-style: preserve-3d;
  perspective: 500px;
  position: absolute;
`

const Figure = styled.figure`
  display: block;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  transition: transform 600ms ease;
  backface-visibility: hidden;
  z-index: 99;
`
// TODO: box-reflect crashing the app. Resolve issue
// -webkit-box-reflect: below 1px -webkit-linear-gradient(bottom, rgba(black, .6) , rgba(black, .1) 20%, rgba(black, 0) 30%, rgba(black, 0));

const Cover = styled.div`
  display: block;
  width: 100%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
`

const Preloader = styled.div`
  display: hidden;
`

const Text = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 0.9em;
  color: white;
  padding: 5px;
  overflow: hidden;
`

const Actions = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  button {
    border: 0;
    padding: 5px;
    margin: 2px;
    background: transparent !important;

    &:focus {
      outline: none;
    }
  }
`

const ArrowLeft = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 13px solid white;
  filter: drop-shadow(black 0 0 5px);
`
const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 13px solid white;
  filter: drop-shadow(black 0 0 5px);
`

const TOUCH = {
  move: false,
  lastX: 0,
  sign: 0,
  lastMove: 0,
}

const TRANSITIONS = [
  'transitionend',
  'oTransitionEnd',
  'otransitionend',
  'MSTransitionEnd',
  'webkitTransitionEnd',
]

const HandleAnimationState = function() {
  this._removePointerEvents()
}

class Carousel extends Component {
  /**
   * Life cycle events
   */

  containerRef = React.createRef()
  stageRef = React.createRef()

  static propTypes = {
    children: PropTypes.node.isRequired,
    displayQuantityOfSide: PropTypes.number.isRequired,
    navigation: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    enableHeading: PropTypes.bool,
    enableScroll: PropTypes.bool,
    clickable: PropTypes.bool,
    currentFigureScale: PropTypes.number,
    otherFigureScale: PropTypes.number,
    active: PropTypes.number,
    media: PropTypes.any,
    infinite: PropTypes.bool,
    rotation: PropTypes.number,
    transform: PropTypes.func,
    slideStyle: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideBackside: PropTypes.bool,
    gallery: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    onSlide: PropTypes.function,
    autoplay: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    dots: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    squared: PropTypes.number,
  }

  static defaultProps = {
    squared: 0,
    dots: false,
    autoplay: false,
    onSlide: () => {},
    gallery: false,
    hideBackside: false,
    slideStyle: () => ({}),
    transform: (idx, width) => `translateX(${idx * width}px)`,
    rotation: 0,
    displayQuantityOfSide: 2,
    navigation: false,
    enableHeading: false,
    enableScroll: false,
    clickable: true,
    currentFigureScale: 1.5,
    otherFigureScale: 0.8,
    active: 0,
    media: () => 'width: 100%; height: 100%;',
    infinite: false,
    width: 'auto',
    height: 'auto',
  }

  state = {
    mouseEntered: false,
    current: 0, //~~(React.Children.count(this.props.children) / 2),
    move: 0,
    width: this.props.width,
    height: this.props.height,
  }

  componentDidMount() {
    this.updateDimensions()
    const length = React.Children.count(this.props.children)
    this.setState({ current: this.props.init || 0 })
    if (this.props.autoplay)
      this.autoplayInterval = setInterval(
        () => this._handleNextFigure(true),
        this.props.autoplay === true ? 6000 : this.props.autoplay
      )
    TRANSITIONS.forEach(event => {
      for (let i = 0; i < length; i++) {
        const figureID = `figure_${i}`
        this.refs[figureID].addEventListener(
          event,
          HandleAnimationState.bind(this)
        )
      }
    })

    const eventListener = window && window.addEventListener

    if (eventListener) {
      window.addEventListener('resize', this.updateDimensions.bind(this))
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      this.updateDimensions(this.props.active)
    }
  }

  componentWillUnmount() {
    const length = React.Children.count(this.props.children)

    if (this.autoplayInterval) clearInterval(this.autoplayInterval)

    TRANSITIONS.forEach(event => {
      for (let i = 0; i < length; i++) {
        const figureID = `figure_${i}`
        this.refs[figureID].removeEventListener(
          event,
          HandleAnimationState.bind(this)
        )
      }
    })
  }

  updateDimensions(active) {
    const { displayQuantityOfSide } = this.props
    const length = React.Children.count(this.props.children)
    const center = this._center()
    let state = {
      width: this.containerRef.current?.offsetWidth,
      height: this.containerRef.current?.offsetHeight,
    }
    const baseWidth = state.width / (displayQuantityOfSide * 2 + 1)
    let activeImg = typeof active === 'number' ? active : this.props.active
    if (typeof active === 'number' && ~~active < length) {
      activeImg = ~~active
      let move = 0
      move = baseWidth * (center - activeImg)

      state = Object.assign({}, state, {
        current: active,
        move,
      })
    }
    this.setState(state)
  }

  render() {
    const {
      enableScroll,
      navigation,
      infinite,
      media,
      squared,
      gallery,
      children,
    } = this.props
    const { width, height, current } = this.state
    const renderPrevBtn = infinite ? true : current > 0
    const renderNextBtn = infinite ? true : current < children.length - 1

    const style =
      Object.keys(media).length !== 0 || typeof media === 'function'
        ? {}
        : { width: this.props.width, height: this.props.height }

    /*if (gallery)
      style.paddingBottom =
        (gallery?.height || 4) + (gallery?.titles ? 2.5 : 0.5) + 'rem'*/

    const setMouseEntered = bool => () => this.setState({ mouseEntered: bool })
    return (
      <Container
        style={style}
        media={
          typeof media === 'function'
            ? media
            : () => 'height: 100%; width:100%;'
        }
        onWheel={enableScroll ? this._handleWheel.bind(this) : null}
        onMouseEnter={setMouseEntered(true)}
        onMouseLeave={setMouseEntered(false)}
        onTouchStart={this._handleTouchStart.bind(this)}
        onTouchMove={this._handleTouchMove.bind(this)}
        onKeyDown={this._keyDown.bind(this)}
        ref={this.containerRef}
        hideOverflow={this.props.hideBackside}
      >
        <Coverflow
          squared={this.props.height === 'fit-content' && squared}
          style={
            squared && this.props.height !== 'fit-content'
              ? {
                  width: Math.min(width, height / squared) || width,
                  height: Math.min(width * squared, height) || height,
                  flex: 'unset',
                }
              : {}
          }
          hideOverflow={this.props.hideBackside}
        >
          <Preloader />
          <Stage ref={this.stageRef}>{this._renderFigureNodes()}</Stage>
        </Coverflow>
        {navigation &&
          (navigation === true ? (
            <Actions>
              {renderPrevBtn && (
                <button type="button" onClick={() => this._handlePrevFigure()}>
                  <ArrowLeft />
                </button>
              )}
              {renderNextBtn && (
                <button type="button" onClick={() => this._handleNextFigure()}>
                  <ArrowRight />
                </button>
              )}
            </Actions>
          ) : (
            navigation({
              current,
              prev: this._handlePrevFigure,
              next: () => this._handleNextFigure(),
              specific: idx => () => this._handleSpecificFigure(idx),
            })
          ))}
        {gallery &&
          (gallery.ref?.current
            ? ReactDOM.createPortal(this._renderGallery(), gallery.ref.current)
            : this._renderGallery())}
      </Container>
    )
  }

  /**
   * Private methods
   */
  _center() {
    const length = React.Children.count(this.props.children)
    return ~~(length / 2)
  }

  _keyDown(e) {
    if (e.keyCode === 37) {
      this._handlePrevFigure()
    } else if (e.keyCode === 39) {
      this._handleNextFigure()
    }
  }

  _handleFigureStyle(
    index,
    current,
    transform = this.props.transform,
    hideBackside = this.props.hideBackside
  ) {
    const {
      displayQuantityOfSide,
      rotation,
      children,
      infinite,
      offset,
    } = this.props
    const { width, height, direction } = this.state
    const style = {}
    const baseWidth = width / (displayQuantityOfSide * 2 + 1)
    const length = React.Children.count(this.props.children)
    // Handle opacity
    const idx = infinite
      ? ((Math.floor(length * 1.5) + index - current) % length) -
        Math.floor(length / 2)
      : index - current
    const depth = Math.abs(idx)
    style.zIndex = `${100 - depth}`
    style.transform = transform(idx, width)
    style.transition =
      !direction ||
      (hideBackside &&
        Math.abs(idx) >= length / 2 - Math.abs(direction) &&
        Math.sign(idx) === Math.sign(direction))
        ? 'none'
        : 'transform 600ms ease'
    return style
  }

  _handleFigureClick = (index, action, e) => {
    if (!this.props.clickable) {
      e.preventDefault()
      return
    }

    this.stageRef.current.style.pointerEvents = 'none'
    if (this.state.current === index) {
      // If on the active figure
      if (typeof action === 'string') {
        // If action is a URL (string), follow the link
        window.open(action, '_blank')
      }

      this._removePointerEvents()
    } else {
      // Move to the selected figure
      e.preventDefault()
      this._handleSpecificFigure(index)
    }
  }

  _renderFigureNodes = () => {
    const { enableHeading } = this.props
    const { current } = this.state

    const figureNodes = React.Children.map(
      this.props.children,
      (child, index) => {
        const style = this._handleFigureStyle(index, current)
        return (
          <Figure
            key={index}
            onClick={e =>
              this._handleFigureClick(index, child.props['data-action'], e)
            }
            style={style}
            ref={`figure_${index}`}
          >
            {child}
            {enableHeading && <Text>{child.props.heading}</Text>}
          </Figure>
        )
      }
    )
    return figureNodes
  }

  _renderGallery = () => {
    const { children, gallery, infinite } = this.props
    const { current } = this.state

    return (
      <>
        <Gallery current={current} height={gallery.height || 4}>
          {(infinite || current > 0) && (
            <Left onClick={() => this._handlePrevFigure()} />
          )}
          {(infinite || current < children.length) && (
            <Right onClick={() => this._handleNextFigure()} />
          )}
          {React.Children.map(children, (child, idx) => {
            const style = this._handleFigureStyle(
              idx,
              current,
              idx => `translateX(${idx * 100}%)`
            )
            return (
              <>
                <GalleryItem hide key={child.props.key + '0'}>
                  <GalleryImage>{child}</GalleryImage>
                  {child.props.title && (
                    <GalleryTitle
                      onClick={() => this._handleSpecificFigure(idx)}
                    >
                      {child.props.title}
                    </GalleryTitle>
                  )}
                </GalleryItem>
                <GalleryItem
                  key={child.props.key + '1'}
                  style={style}
                  onClick={() => this._handleSpecificFigure(idx)}
                >
                  <GalleryImage>{child}</GalleryImage>
                  {child.props.title && (
                    <GalleryTitle
                      onClick={() => this._handleSpecificFigure(idx)}
                    >
                      {child.props.title}
                    </GalleryTitle>
                  )}
                </GalleryItem>
              </>
            )
          })}
        </Gallery>
      </>
    )
  }

  _removePointerEvents() {
    this.stageRef.current.style.pointerEvents = 'auto'
  }

  _hasPrevFigure = () => this.state.current - 1 >= 0

  _hasNextFigure = () => this.state.current + 1 < this.props.children.length

  _handlePrevFigure = (n = 1) => {
    const {
      displayQuantityOfSide,
      infinite,
      onSlide,
      children,
      originalLength,
    } = this.props
    const { width } = this.state
    const { current } = this.state
    const baseWidth = width / (displayQuantityOfSide * 2 + 1)
    const distance =
      this._center() - (current - 1 < 0 ? children.length - 1 : current - 1)
    const move = distance * baseWidth

    if (current - 1 >= 0) {
      this.setState({ current: current - 1, move, direction: -1 })
      onSlide((current - 1) % originalLength)
    }
    if (current - 1 < 0 && infinite) {
      onSlide((children.length - 1) % originalLength)
      this.setState({
        current: children.length - 1,
        move,
        direction: -1,
      })
    }
  }

  _handleSpecificFigure = idx => {
    const {
      infinite,
      children,
      displayQuantityOfSide,
      onSlide,
      originalLength,
    } = this.props
    const { current, width } = this.state
    const baseWidth = width / (displayQuantityOfSide * 2 + 1)
    const distance = this._center() - idx
    const move = distance * baseWidth

    let direction = idx - current
    if (infinite && 2 * Math.abs(idx - current) > children.length)
      direction = Math.sign(direction) * (Math.abs(direction) - children.length)
    this.setState({
      current: idx,
      move,
      direction,
      lastUserInteraction: Date.now(),
    })
    onSlide(idx % originalLength)
  }

  _handleNextFigure = autoplay => {
    const {
      displayQuantityOfSide,
      infinite,
      children,
      onSlide,
      originalLength,
    } = this.props
    const { width } = this.state
    const { current, mouseEntered } = this.state
    const baseWidth = width / (displayQuantityOfSide * 2 + 1)
    const distance =
      this._center() - (current + 1 >= children.length ? 0 : current + 1)
    const move = distance * baseWidth

    if (autoplay && mouseEntered) return

    if (current + 1 < children.length) {
      this.setState({ current: current + 1, move, direction: 1 })
      onSlide((current + 1) % originalLength)
    }
    if (current + 1 >= children.length && infinite) {
      this.setState({ current: 0, move, direction: 1 })
      onSlide(0)
    }
  }

  _handleWheel(e) {
    const { infinite } = this.props
    const delta =
      Math.abs(e.deltaY) === 125
        ? e.deltaY * -120
        : e.deltaY < 0
        ? -600000
        : 600000
    const count = Math.ceil(Math.abs(delta) / 120)

    if (count > 0) {
      const sign = Math.abs(delta) / delta
      let func = null

      if (sign > 0 && (this._hasPrevFigure() || infinite)) {
        e.preventDefault()
        func = this._handlePrevFigure()
      } else if (sign < 0 && (this._hasNextFigure() || infinite)) {
        e.preventDefault()
        func = this._handleNextFigure()
      }

      if (typeof func === 'function') {
        for (let i = 0; i < count; i++) func()
      }
    }
  }

  _handleTouchStart(e) {
    TOUCH.lastX = e.nativeEvent.touches[0].clientX
    TOUCH.lastMove = this.state.move
  }

  _handleTouchMove(e) {
    e.preventDefault()
    const { displayQuantityOfSide } = this.props
    const { width } = this.state

    const clientX = e.nativeEvent.touches[0].clientX
    const lastX = TOUCH.lastX
    const baseWidth = width / Math.max(5, displayQuantityOfSide * 2 + 1)
    const move = clientX - lastX
    const sign = Math.sign(move)

    if (Math.abs(move) >= baseWidth) {
      let fn = null
      if (sign > 0) {
        fn = this._handlePrevFigure()
      } else if (sign < 0) {
        fn = this._handleNextFigure()
      }
      if (typeof fn === 'function') {
        TOUCH.lastX = clientX
        fn()
      }
    }
  }
}

Carousel.displayName = 'Carousel'

export default function CarouselWrapper({ children, ...props }) {
  return (
    <Carousel
      {...props}
      infinite={props.gallery && children?.length < 3 ? false : props.infinite}
      originalLength={children?.length}
    >
      {(children?.length === 4 || children?.length === 3) && props.gallery
        ? children?.concat(children)
        : children}
    </Carousel>
  )
}
